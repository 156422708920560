<template>
	<div>
		<Loader v-if="sourcesLoad" />
		<div v-else class="mt-10 mt-5 container mx-auto">
			<div class="md:grid md:grid-cols-1 md:gap-6">
				<div class="md:col-span-1">
					<div class="px-4 sm:px-0">
						<h3 class="text-lg font-medium leading-6 text-gray-900">
							{{ $i18n("Source") }}  {{ $i18n("feeds") }} - {{ feedName }}
						</h3>
					</div>
				</div>
				<div class="mt-5 md:mt-0 md:col-span-2">
					<form @submit.prevent="update">
						<div class="shadow overflow-hidden sm:rounded-md">
							<div class="px-4 py-5 bg-white sm:p-6">
								<div class="grid grid-cols-6 gap-6">
									<div class="col-span-10 sm:col-span-3">
										<label for="type" class="block text-sm font-medium text-gray-700">{{
											$i18n("Source type")
										}}</label>
										<t-select
											name="type"
											id="type"
											:options="['telegram', 'web', 'twitter']"
											v-model="source.type"
										></t-select>
									</div>
									<div class="col-span-10 sm:col-span-3">
										<label for="url" class="block text-sm font-medium text-gray-700">{{
											$i18n("Url")
										}}</label>
										<input
											v-model="source.url"
											type="text"
											name="url"
											id="url"
											autocomplete=""
											class="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
										/>
									</div>
								</div>
							</div>
							<div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
								<button
									@click="update"
									type="button"
									class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
								>
									{{ $i18n("Save") }}
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";

export default {
	name: "Source",
	props: {
		feedId: {
			type: String,
		},
		id: {
			type: String,
		},
		method: {
			type: String,
		},
	},
	data() {
		return {
			source: {
				name: "",
				type: "telegram",
				url: "",
				external_id: undefined,
			},
			error: false,
			feedName: "",
		};
	},
	components: {
		Loader,
	},
	computed: {
		...mapGetters("sources", ["sourcesLoad"]),
	},
	methods: {
		...mapActions("sources", [
			"createSource",
		]),
		...mapActions("feeds", ["fetchFeedById"]),
		async update() {
			try {
				await this.createSource({
					feedId: this.feedId,
					payload: this.source,
				});
				this.$router.go(-1);
			} catch (error) {
				console.debug(error);
			}

			return;
		},
		async fetchFeedName() {
			try {
				const { name } = await this.fetchFeedById(this.feedId);
				this.feedName = name;
			} catch (error) {
				console.debug(error);
			}
		},
	},
	async created() {
		try {
			await this.fetchFeedName();
		} catch (error) {
			console.debug(error);
		}
	},
};
</script>
